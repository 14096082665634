// Raw Colors
$accent30: #FF8AAD;
$accent50: #EE4C7D;
$purp30: #37244B;
$purp80: #16002D;
$purp90: #0E001C;
$gray10: #FBFBFC;
$yell40: #EBDA80;

// Semantic Mappings
$backgroundColor: $purp90;
$textColor: $gray10;
$primary: $accent50;
$linkColor: $primary;