@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,900,900i&display=swap');
@import 'palette';

body {
  margin: 0;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $textColor;
  font-size: 18px;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-moz-selection { background: lighten($yell40, 10%); }
*::selection { background: lighten($yell40, 10%); }