@import '../styles/palette';
@import '../styles/utils';

.card {
  max-width: $tablet-width;
  background-color: $purp30;
  border-radius: 32px;
  padding: 44px 114px;
  margin: 60px auto 180px;
  position: relative;

  @include phone {
    padding: 24px;
  }
}

.quotemark {
  font-size: 200px;
  color: $purp80;
  position: absolute;
  z-index:0;
  left: 12px;
  top: -30px;
  font-style: italic;

  @include phone {
    display: none;
  }
}

.quote {
  font-size: 27px;
  font-style: italic;
  line-height: 1.45;
  position: relative;
  z-index: 1;

  @include phone {
    font-size: 20px;
  }
}

.who-holster {
  position: absolute;
  bottom: -50px;
  right: 42px;
  background-color: $gray10;
  color: $purp90;
  border-radius: 18px;
  padding: 12px 28px 12px 48px;
  box-shadow: 0 8px 40px rgba(0,0,0,1);

  &:hover {
    color: $purp90;
  }

  @include phone {
    right: 0px;
    bottom: -60px;
    padding: 12px 12px 12px 28px;
  }

  img {
    position: absolute;
    left: -84px;
    top: -20px;
    border-radius: 50%;
    width: 110px;
    box-shadow: 0 8px 40px rgba(0,0,0,.6);

    @include phone {
      left: -36px;
      width: 60px;
      top: 30px;
    }
  }

  .title {
    font-weight: 400;
  }
}