@import '../styles/palette';
@import '../styles/utils';

footer {
  background-color: $purp80;
  margin-top: $sizing-xs;

  @include tablet {
    width: calc(100% + 60px);
    margin-left: -32px;
  }

  .holster {
    height: 400px;
    max-width: 1440px;
    display: flex;
    margin: 0 auto;

    img {
      height: 100%;
    }
    .adjacent {
      max-width: $tablet-width;
      padding-left: $sizing-xs;
      padding-top: $spacing-lg;
    }

    @include tablet-large {
      display: block;
      height: auto;
      text-align: center;
      padding: 0 32px;

      .profile, .adjacent {
        padding: 0;
        max-width: none;
      }

      .profile {
        border-radius: 18px;
        width: 50%;
        margin-top: 32px;
      }
    }

    @include phone {
      padding: 0;

      p { 
        display: none;
      }

      .profile {
        border-radius: 0;
        width: 100%;
        margin-top: 0;
      }
    }
  }
}

.socialStrip {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 32px 0 0 -12px;

  @include tablet-large {
    padding-bottom: 32px;
    margin: 32px 0 0;
  }

  @include phone {
    padding-bottom: 32px;
    margin: 32px 0 0;
  }

  .social-link {
    opacity: .8;
    transition: opacity 100ms ease;
    position: relative;
    padding: 0 12px;
    text-align: center;

    img {
      width: 80%;
      @include phone {
        width: 100%;
      }
    }

    .tooltip {
      text-align: center;
      pointer-events: none;
      background-color: $gray10;
      border-radius: 8px;
      color: $purp90;
      position: absolute;
      top: -55px;
      left: 50%;
      opacity: 0;
      transform: translate(-50%, 10px) scale(.95);
      transition: opacity 80ms ease, transform 180ms cubic-bezier(0.54, 0.35, 0, 1.42);
      width: 230px;
      padding: 8px 0;
      box-shadow: 0 4px 20px rgba(0,0,0,.4);

      @include tablet {
        display: none;
      }

      .arrow {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0; 
        height: 0; 
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $gray10;
      }
    }
    &:hover {
      opacity: 1;
      .tooltip {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    }
  }
}